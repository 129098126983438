// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require("../../package.json").version,
  production: false,
  environment: "dev", //  localhost,dev

  apiBase: "https://depotapi.dev.recyclingapp.net",
  apiBaseCustomer: "https://api.dev.recyclingapp.net",
  Cognito: {
    domain: "direct-collect-dev-admin-domain.auth.ap-southeast-2.amazoncognito.com",
    userPoolId: "ap-southeast-2_exxl8kkn8",
    userPoolWebClientId: "2t2sq84ectihc81g3nbt32tjff",
    redirectSignIn: "https://d304tf3l83o6bi.cloudfront.net/user-home",
    redirectSignOut: "https://d304tf3l83o6bi.cloudfront.net"
  },
  routeOptimization: {
    baseUrl: 'https://flexops-api.adionatech.com/api/v1'
  },
  S3: {
    bucket: 'cdstestbucket-directcollect',
    identityPoolId: 'ap-southeast-2:afc77d32-8da6-4232-94a5-b62c3c2bad27'
  },

  stripe_pk: 'pk_test_51LZ2OoJWH8z1ad0RUSmYUtu6xYhbPhRizIPEj9Q6PbYftO9PPu65Bqxf16e0xK76BOuWMJdimsXvA7HdTo8ZxEJr008NXJ8tdo',
  maps_key: 'AIzaSyCoi9BscJgb32_p3ZoWlW5d1Ll6eaxGq4g'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
