import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { appParam} from "../../helper/appSettings";
import { AppService } from "../../services/app.service";
import { ApiService } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { alertAttributes } from "../../helper/appAlert";
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';

//  row structure
export interface TableRow {
  id: string; // used for the checkbox column
  date: string;
  weekday: string;
  description: string;
  dropoff_actual: number;
  dropoff_limit: number;
  changeover_actual: number;
  changeover_limit: number;
  block_order: boolean;
  context_menu: string;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa14001',
  templateUrl: './uxa14001.component.html',
  styleUrls: ['./uxa14001.component.scss']
})
export class Uxa14001Component implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tabGroup') tabGroup

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);

  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;
  tabList: {[key: string]: number} = {};

  _currentDepot: string;
  _currentContext: TableRow;

  //  columns to be displayed in the table
  displayedColumns: string[] = [
    'id',
    'date',
    'weekday',
    'description',
    'dropoff_actual',
    'dropoff_limit',
    'changeover_actual',
    'changeover_limit',
    'block_order',
    'context_menu',
  ];

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();
  }

  async ngOnInit(): Promise<void> {
    await this.getData()
  }

  async ngAfterViewInit(): Promise<void> {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async getData() {
    this.appService.appSpinner(true);

    // get all the users depot access
    let depotIds = [];
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      this.tabList[depot.Depot.desc] = depot.Depot.id
      depotIds.push(depot.Depot.id)
    }

    this.appService.appSpinner(false);
  }

  async updateTableData($event) {
    if ($event === undefined) {
      this._currentDepot = this.tabGroup._allTabs.first.textLabel
    } else if ($event.tab !== undefined) {
      this._currentDepot = $event.tab.textLabel
    } else {
      this._currentDepot = $event
    }

    let depot = this.tabList[this._currentDepot].toString()

    this.appService.appSpinner(true);

    let _data = await this.apiService.getDateInstancesWithDetail(depot)
    let tempData = []

    try {
      for (let _row of _data) {
        tempData.push({
          id: _row['depot_day_id'],
          date: _row['available_date'],
          weekday: _row['weekday'],
          description: _row['status_description'],
          dropoff_actual: _row['actual_dropoff_orders'],
          dropoff_limit: _row['dropoff_limit'],
          changeover_actual: _row['actual_changeover_orders'],
          changeover_limit: _row['changeover_limit'],
          block_order: _row['order_block'],
          context_menu: _row['...']
        });
      }
    } catch (err) {
      console.log('err', err);
    }

    setTimeout(() => {
      this.dataSource = new MatTableDataSource(tempData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.appService.appSpinner(false);
    }, 500);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async blockOrderClick(row: any) {
    this._currentContext = row;
    let depot = this.tabList[this._currentDepot].toString()
    let date = new Date(this._currentContext.date)
    let dateString = formatDate(date, 'yyyy-MM-dd', 'en-GB')

    try {
      this.appService.appSpinner(true);
      const res = await this.apiService.toggleAvailableDateBlockFlag(depot, dateString)

      this.appService.appSpinner(false);
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Successfully toggled block order',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })
      await this.updateTableData(this._currentDepot)
    } catch (err) {
      console.log(err)
      this.appService.appSpinner(false);
    }
  }

  async editRowClick(row: any) {
    this._currentContext = row;
  }

  async regenerateAvailableDays() {
    this.appService.appSpinner(true);
    try {
      let res = await this.apiService.updateAvailableDates();
    } catch (err) {
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Could not generate available dates!',
        body: '',
        displayNotification: true
      })
      this.appService.appSpinner(false);
      return;
    }
    this.appService.sendNotification({
      type: alertAttributes.types.success,
      message: 'Regenerated available dates!',
      body: '',
      displayNotification: true
    })
    this.appService.appSpinner(false);
  }

  async openAddExceptionDialog() {
    let dialogRef = this.dialog.open(AddExceptionDialog, {
      autoFocus: true,
      minWidth: '25%',
      height: 'auto',
      data: {
        depot: this._currentDepot,
        date: this._currentContext.date,
        id: this._currentContext.id,
      }
    });
    dialogRef.afterClosed().subscribe(async (result: boolean) => {
        if (result) await this.updateTableData(this._currentDepot);
      }
    )
  }

}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-exception-dialog',
  templateUrl: 'add-exception-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AddExceptionDialog {
  depot: string;
  date: Date;
  id: string;
  isBtnDisabled: boolean = false;
  dateString: string;
  description: string;
  newDropoffs: string;
  newChangeovers: string;


  constructor(
    public dialogRef: MatDialogRef<AddExceptionDialog>,
    private route: ActivatedRoute,
    private appService: AppService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      depot: string,
      date: string,
      id: string,
    },
  ) {
    this.depot = this.data.depot;
    this.date = new Date(this.data.date)
    this.id = this.data.id
    this.dateString = formatDate(this.date, 'yyyy-MM-dd', 'en-GB')

  }

  closeDialogRef() {
    this.dialogRef.close(true)
  }

  async save() {
    this.appService.appSpinner(true);
    this.isBtnDisabled = true;

    try {
      const res = await this.apiService.createDepotDayException(
        this.id, this.dateString, this.description, this.newDropoffs, this.newChangeovers
      )

      this.appService.appSpinner(false)
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Exception has been added',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

      setTimeout(() => {
        this.closeDialogRef()
      }, 500);

    } catch (err) {
      this.appService.appSpinner(false);
      this.isBtnDisabled = false;
      console.log(err)
    }
  }
}

