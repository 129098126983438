import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { appParam } from 'src/app/helper/appSettings';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Uxa9003Component } from '../003/uxa9003.component';
import { Uxa9002Component } from '../002/uxa9002.component';
import { Uxa9002cComponent } from "../002c/uxa9002c.component";
import { Uxa9002gComponent } from "../002g/uxa9002g.component";
import { alertAttributes } from "../../helper/appAlert";
import { Router } from '@angular/router';

//  row structure
export interface TableRow {
  id: string; // used for the checkbox column
  type: string;
  user_id: string;
  first_name: string;
  last_name: string;
  street_address: string;
  mobile: string;
  onboard_date: string;
  verified: boolean;
  context_menu: string;
  // selected: boolean;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa9001',
  templateUrl: './uxa9001.component.html',
  styleUrls: ['./uxa9001.component.scss']
})
export class Uxa9001Component implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);
  // selection = new SelectionModel<TableRow>(true, []);

  // used to store the selected bin
  _currentContext: TableRow;

  //  columns to be displayed in the table
  displayedColumns: string[] = [
    'id',
    'first_name',
    'last_name',
    'type',
    'user_id',
    'mobile',
    'street_address',
    'onboard_date',
    'verified',
    'context_menu'
  ];

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  //  get data from API
  async getData() {
    this.appService.appSpinner(true);

    // get all the users depot access
    let depotIds = [];
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      depotIds.push(depot.Depot.id)
    }

    //  get the customers from the db
    let _data = await this.apiService.getCustomersByDepot(depotIds.toString());

    //  update the array for the table
    this.dataSource.data = [];
    try {
      for (let _row of _data) {
        // filter out Dummy customer DEPOT
        // if (_row['customer_address'] != null) {
          this.dataSource.data.push({
            id: _row['id'],
            type: _row['customer_type'],
            user_id: _row['customer_id'],
            first_name: _row['first_name'],
            last_name: _row['last_name'],
            street_address: _row['customer_address'] ? _row['customer_address'] : 'No address' ,
            mobile: _row['mobile'],
            onboard_date: _row['onboard_date'],
            verified: _row['verified'],
            context_menu: '...',
            // selected: false
          });
        // }
      }
    } catch (err) {
      console.log('err', err);
    }
    // this.selection.clear();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.appService.appSpinner(false);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.filteredData.length;
  //   return numSelected === numRows;
  // }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected()
  //     ? this.selection.clear()
  //     : this.dataSource.filteredData.forEach((row) => this.selection.select(row));
  // }

  // toggle(row, event) {
  //   event ? this.selection.toggle(row) : null;
  // }

  /** The label for the checkbox on the passed row */
  // checkboxLabel(row?: TableRow): string {
  //   if (!row) return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  //   else return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  // }

  contextClick(row: any) {
    this._currentContext = row;
  }

  openAddCustomerDialog() {
    let dialogRef = this.dialog.open(Uxa9003Component);
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) await this.getData();
    });
  }

  async openCustomerCardDialog() {
    let dialogRef = this.dialog.open(Uxa9002Component, {
      data: { id: this._currentContext.id, uid: this._currentContext.user_id, type: this._currentContext.type }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) await this.getData();
    });
  }

  async openCreateOrderDialog() {
    let dialogRef = this.dialog.open(Uxa9002cComponent, {
      data: { id: this._currentContext.id, uid: this._currentContext.user_id}
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) await this.getData();
    });
  }

  async openDeleteCustomerDialog() {
    let dialogRef = this.dialog.open(DeleteConfirmDialog, {
      autoFocus: true,
      minWidth: '25%',
      height: 'auto',
      data: { userId: this._currentContext.user_id }
    });

    dialogRef.afterClosed().subscribe(async (result: boolean) => {
      if (result) {
        let res = await this.apiService.deleteCustomer(this._currentContext.id, this._currentContext.user_id)
        if (res[0].deleted) {
          this.appService.sendNotification({
            type: alertAttributes.types.info,
            message: 'Customer has been successfully deleted!',
            body: '',
            displayNotification: true,
            autoCloseAfter: 3
          })
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          this.appService.sendNotification({
            type: alertAttributes.types.warning,
            message: 'Customer cannot be deleted!',
            body: '',
            displayNotification: true,
            autoCloseAfter: 3
          })
        }
      }
    });
  }

  async openChangeOrderDateDialog() {
    console.log("Will open change order date dialog")
  }

  async disableCustomer() {
    console.log('Will disable the customer')
    console.log("Customer ID: ", this._currentContext.id)
  }
}

export interface DialogData {
  userId: string,
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'delete-confirm-dialog',
  templateUrl: 'delete-confirm-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DeleteConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
  }
}
